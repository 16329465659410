<template>
    <div></div>
</template>

<script>
export default {
    name: "index",
    layout: {
        name: 'admin',
    }
}
</script>

<style scoped>

</style>